import React, { useCallback } from 'react';
import { PageWrapper } from 'components/page';
import { PageLoadingIndicator } from 'components/loading-indicator';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { withAPI } from 'hocs';
import { useEffect } from 'react';
import { getFormattedDate } from 'components/dateHandle';
import { DocumentIcon } from '@heroicons/react/outline';


const OpenDocuments = ({ httpRequest }) => {
  const { vin, company_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [docs, setDocs] = useState([]);
  const [truck, setTruck] = useState({});
  const [docMeta, setDocMeta] = useState({});

  const loadDocs = useCallback(async () => {
    await httpRequest({
      method: 'get',
      url: `truck/documents/${company_id}/${vin}`,
      noToken: true,
    }).then((response) => {
      const {
        data: {
          data: {
            truck,
            docs,
          },
          meta,
        },
      } = response;
      setDocMeta(meta);
      setDocs(docs);
      setTruck(truck);
    })
    .finally(() => {
      setIsLoading(false);
    })
  }, [httpRequest, vin, company_id]);

  useEffect(() => {
    setIsLoading(true);
    loadDocs();
  }, [httpRequest, loadDocs, vin])

  return (
    <div className="grid">
      <div className='place-self-center'>
        <PageWrapper>
        <PageLoadingIndicator loading={isLoading} />
        <div style={{ minWidth: '320px', minHeight: '320px' }}>
        <img id="brand-logo" className="h-10 mb-4" src='/assets/logo-company.png' alt="logo" />
        <div className='mb-4 pb-4 border-b'>Documents for truck <b>{vin}</b> (<b>{truck.truck_no}</b>)</div>
        {docs?.filter((i) => i.truck_id)?.map((item) => (
          <div className="flex text-sm text-black-600 mb-3 gap-3">
            <a
              key={item.id}
              href={item.url}
              target='_blank'
              className="flex text-sm text-black-600 hover:text-black-400 items-between w-full" rel="noreferrer"
            >
              <DocumentIcon
                className="relative -top-0.5 mr-1.5 h-6 w-6 text-black-500"
                aria-hidden="true"
              />
              {item.type === 'NA'
                ? item.name : (docMeta?.types_system[item.type] || docMeta?.types_co[item.type])}
              {item?.expiry_at && (
                <span
                  className={item?.is_active
                    ? 'text-green-regular text-xs pl-1 md:pl-5 self-center ml-auto'
                    : 'text-red-regular text-xs pl-1 md:pl-5 self-center ml-auto'}
                >
                  Exp:
                  {' '}
                  {getFormattedDate(item?.expiry_at)}
                </span>
              )}
            </a>
          </div>
        ))}

        {docs?.filter((i) => i.truck_id)?.length > 0 && <hr className="mb-2" />}

        {docs?.filter((i) => !i.truck_id)?.map((item) => (
          <div className="flex text-sm text-black-600 mb-3 gap-3">
            <a
              key={item.id}
              href={item.url}
              target='_blank'
              className="flex text-sm text-black-600 hover:text-black-400 items-between w-full" rel="noreferrer"
            >
              <DocumentIcon
                className="relative -top-0.5 mr-1.5 h-6 w-6 text-black-500"
                aria-hidden="true"
              />
              {item.type === 'NA'
                ? item.name : (docMeta?.types_system[item.type] || docMeta?.types_co[item.type])}
              {item?.expiry_at && (
                <span
                  className={item?.is_active
                    ? 'text-green-regular text-xs pl-1 md:pl-5 self-center ml-auto'
                    : 'text-red-regular text-xs pl-1 md:pl-5 self-center ml-auto'}
                >
                  Exp:
                  {' '}
                  {getFormattedDate(item?.expiry_at)}
                </span>
              )}
            </a>
          </div>
        ))}
        </div>
        </PageWrapper>
      </div>
    </div>
  )
}

export default _.flowRight([withAPI])(OpenDocuments);