import { React, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowLeftIcon, ArrowRightIcon, DocumentIcon, XIcon } from '@heroicons/react/outline';
import { PageLoadingIndicator } from 'components/loading-indicator';

import { isFileExt, isVideoExt } from 'utils';
import { PreviewList } from 'components/photo-preview';
import { usePreviewFileNavigate } from 'hooks';
import _ from 'lodash';

export const videoExtensions = new Set(['mp4', 'mov', 'wmv', 'flv', 'avi', 'webm', 'mkv']);
export const fileExtensions = new Set(['pdf', 'doc', 'docx']);

const maxViewElements = 3;
const previewHeight = '60vh';

const ModalFilePreview = ({
  open,
  setOpen,
  url,
  withNavigationButtons,
  withFilesPreview,
  fileName,
  files,
}) => {
  const imageExtensions = new Set(['jpg', 'png', 'gif', 'tiff', 'bmp', 'jpeg', 'svg', 'webp', 'avif', 'heic']);

  const { selectedFile, setSelectedFile, onSetNextFile, onSetPrevFile } = usePreviewFileNavigate(files);

  const isMMR = selectedFile?.url?.split('/')?.pop() === 'mmr';
  const isSmi = selectedFile?.url?.split('/')?.pop() === 'smi';
  const fileExt = (isMMR || isSmi) ? 'pdf' : selectedFile?.url?.toLocaleLowerCase()?.split('.')?.pop();
  const canPreview = videoExtensions.has(fileExt) || imageExtensions.has(fileExt) || fileExtensions.has(fileExt);

  const [isFilePreviewLoading, setIsFilePreviewLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const ButtonsConsumer = ({ StrategyComponent }) => 
    <StrategyComponent
      setIsFilePreviewLoading={setIsFilePreviewLoading}
      onSetNextFile={onSetNextFile}
      onSetPrevFile={onSetPrevFile}
    />

  useEffect(() => {
    setIsFilePreviewLoading(true);

    if (url && _.isString(url) && _.isEmpty(files)) {
      setSelectedFile({ url });
    }
    
    if ((files?.length > 0) && !url) {
      let firstFile = files[0];
      if (!firstFile.url && firstFile.thumbnail_url) {
        firstFile.url = firstFile.thumbnail_url;
      }
      setSelectedFile(firstFile);
    }
    
    if ((files?.length > 0) && url) {
      const file = _.find(files, (i) => i.url === url);
      if (file) {
        setSelectedFile(file);
      } else {
        const file_th = _.find(files, (i) => i.thumbnail_url === url);
        if (file_th) {
          file_th.url = file_th.thumbnail_url;
          setSelectedFile(file_th);
        }
      }
    }

  }, [url, files, setSelectedFile]);

  useEffect(() => {
    const onResizeEvent = () => {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', onResizeEvent);
    return () => window.removeEventListener('resize', onResizeEvent)
  }, [])

  return (
    <Transition.Root show={open} as={Fragment} data-testid="modal">
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-hidden"
        onClose={setOpen}
        style={{ maxHeight: '100vh' }}
      >
        <div className="flex items-center justify-center p-4 text-center sm:block sm:p-0 h-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left
                shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 w-full sm:w-5/6"
              style={{ width: '90vw', height: '90vh' }}
            >
              <button type="button" className="float-right" onClick={() => setOpen(false)}>
                <XIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-black-400" aria-hidden="true" />
              </button>
              <div className="h-max">
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {fileName || selectedFile?.name || 'File preview'}
                  </Dialog.Title>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense h-full">
                <div className="overflow-x-auto sm:col-span-2 relative h-full">
                  {withNavigationButtons && selectedFile?.url && files?.length > 1 && (
                    <ButtonsConsumer
                      StrategyComponent={
                        (isVideoExt(selectedFile?.url) || isFileExt(selectedFile?.url))
                        ? DefaultNavigationButtons
                        : ImageNavigationButtons
                      }
                    />
                  )}
                  <PageLoadingIndicator loading={isFilePreviewLoading && canPreview} />
                  {canPreview
                    ? (
                      <>
                        {videoExtensions.has(fileExt)
                          ? (
                            <>
                              {/* eslint-disable-next-line */}
                              <video
                                className="w-full"
                                src={selectedFile?.url}
                                controls
                                data-testid="video-file"
                                onLoadedData={() => setIsFilePreviewLoading(false)}
                                style={{ height: previewHeight }}
                                onError={() => setIsFilePreviewLoading(false)}
                              />
                            </>
                          )
                          : (
                            <>
                              {fileExtensions.has(fileExt)
                                ? (
                                  <>
                                    {fileExt !== 'pdf'
                                      ? (
                                        <iframe
                                          title="file preview"
                                          className="w-full object-contain"
                                          style={{ height: previewHeight }}
                                          frameBorder="0"
                                          src={`https://view.officeapps.live.com/op/embed.aspx?src=${selectedFile?.url}`}
                                          data-testid="file-ext"
                                          onLoad={() => setIsFilePreviewLoading(false)}
                                          onError={() => setIsFilePreviewLoading(false)}
                                        />
                                      )
                                      : (
                                        <embed
                                          src={selectedFile?.url}
                                          className="w-full object-contain"
                                          style={{ height: previewHeight }}
                                          data-testid="pdf-file"
                                          onLoad={() => setIsFilePreviewLoading(false)}
                                          onError={() => setIsFilePreviewLoading(false)}
                                        />
                                      )}
                                  </>
                                )
                                : (
                                  <img
                                    alt="Preview"
                                    src={selectedFile?.url || selectedFile?.thumbnail_url}
                                    style={{ height: previewHeight }}
                                    className="w-full object-contain"
                                    onLoad={() => setIsFilePreviewLoading(false)}
                                    onError={() => setIsFilePreviewLoading(false)}
                                    data-testid="file-preview"
                                  />
                                )}
                            </>
                          )}
                      </>
                    )
                    : (
                      <div style={{ height: previewHeight }} className="flex flex-col mx-auto w-max relative">
                        <DocumentIcon
                          className="mb-5 h-6 w-6 text-black-500 mx-auto"
                          aria-hidden="true"
                          data-testid="doc-icon"
                        />
                        <a
                          target="_blank"
                          rel="noreferrer"
                          className="absolute left-1/2 transform -translate-x-1/2 top-10 bg-green-light px-3 py-2 rounded-md text-green-regular hover:text-opacity-70 hover:bg-opacity-70"
                          href={selectedFile?.url}
                        >
                          Download
                        </a>
                      </div>
                    )}
                    {withFilesPreview && open && files?.length > 1 && (
                      <div className="mx-auto w-max">
                        <PreviewList
                          files={files}
                          selectedUrl={selectedFile?.url}
                          maxViewElements={windowWidth <= 550 ? 2 : maxViewElements}
                          setSelectedFile={setSelectedFile}
                        />
                      </div>
                    )}
                  {canPreview && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={selectedFile?.url || selectedFile?.thumbnail_url}
                      className="text-sm text-black-600 mt-5 left-1/2 transform -translate-x-1/2 absolute hover:underline"
                    >
                      Open in new tab
                    </a>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

ModalFilePreview.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  url: PropTypes.string,
  fileName: PropTypes.string,
};

ModalFilePreview.defaultProps = {
  fileName: '',
  open: false,
  setOpen: () => {},
};

export default ModalFilePreview;

const DefaultNavigationButtons = ({ setIsFilePreviewLoading, onSetNextFile, onSetPrevFile }) => (
  <>
    <button
      onClick={() => { setIsFilePreviewLoading(true); onSetPrevFile();}}
      className="absolute top-1/2 -translate-y-1/2 left-0 hover:opacity-50 transition-all text-red z-30 transform
      bg-black-800 bg-opacity-70"
    >
      <ArrowLeftIcon className="w-10 h-10 text-white" />
    </button>
    <button
      onClick={() => { setIsFilePreviewLoading(true); onSetNextFile();}}
      className="absolute top-1/2 -translate-y-1/2 right-0 hover:opacity-50 transition-all text-red z-30 transform
      bg-black-800 opacity-70"
    >
      <ArrowRightIcon className="w-10 h-10 text-white" />
    </button>
  </>
)

const ImageNavigationButtons = ({ setIsFilePreviewLoading, onSetNextFile, onSetPrevFile }) => (
  <div className="absolute flex w-full h-full">
    <button onClick={() => { setIsFilePreviewLoading(true); onSetPrevFile();}} className="hover:opacity-50 transition-all text-red w-1/4">
      <ArrowLeftIcon className="w-10 h-10 text-white bg-black-800 opacity-70" />
    </button>
    <button onClick={() => { setIsFilePreviewLoading(true); onSetNextFile();}} className="hover:opacity-50 transition-all ml-auto text-red w-1/4">
      <ArrowRightIcon className="w-10 h-10 ml-auto text-white bg-black-800 opacity-70" />
    </button>
  </div>
)