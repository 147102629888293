import React, { useState } from 'react'
import { default as ServiceStatusModal } from '../modal/service-status';

const ServiceStatus = ({ serviceStatus }) => {
  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState(false);
  return (
    <>
      {
        isModalDetailsOpen && (
          <ServiceStatusModal
            open={isModalDetailsOpen}
            setOpen={(opn) => { setIsModalDetailsOpen(opn); }}
            serviceStatus={serviceStatus}
          />
        )
      }
      <div
        className='cursor-pointer hover:text-gray-500 hover:underline'
        onClick={() => setIsModalDetailsOpen(true)}
      >
        System Status: <span className={serviceStatus.status === 'OK' ? 'text-indigo-600' : 'text-red-600'}>{serviceStatus.status}</span>
      </div>
    </>
  )
}

export default ServiceStatus