import { ChevronLeftIcon, ChevronRightIcon, MinusIcon, PlusIcon } from '@heroicons/react/outline';
import ModalFilePreview from 'components/modal/file-preview';
import { useCarouselSettings } from 'hooks';
import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';

const styleByPass = {
  pass: {
    'textColor': 'text-green-regular',
    'border': 'border-green-regular',
    'bg': 'bg-green-light',
    'buttonBg': 'bg-green-light',
    'divider': 'border-grey-light',
  },
  fail: {
    'textColor': 'text-white',
    'border': 'border-red-light-hard',
    'bg': 'bg-red-light-hard',
    'buttonBg': 'bg-red-dark',
    'divider': 'border-red-light-hard',
  }
}

const height = 167;
const maxViewElements = 2;

const cardRowsName = ['Name', 'Pass/Fail', 'Notes'];

const formatStyles = (passOrFail, ...args) => {
  if (!_.isEmpty(args)) {
    return args.map((arg) => (
      styleByPass[passOrFail ? 'pass' : 'fail'][arg]
    )).join(' ');
  }
}

const DVIRCard = ({ category, name, passOrFail, notes, files }) => {
  const [isOpened, setIsOpened] = useState(!passOrFail || notes?.length > 0 || files?.length > 0);
  const [span, setSpan] = useState(1);
  const ref = useRef(null);
  const { carouselSettings, onClickBack, onClickNext } = useCarouselSettings({ maxViewElements, files });
  const [filePreviewOpen, setFilePreviewOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});

  useEffect(() => {
    setSpan(Math.ceil(+ref.current?.offsetHeight /  height));
  }, [isOpened])

  const ExpandButton = isOpened ? MinusIcon : PlusIcon;

  return (
    <div
      ref={ref} 
      style={{
        gridRow: isOpened ? `span ${span} / span ${span}` : 'span 1 / span 1',
        height: isOpened ? '100%' : `${height}px`,
      }}
      className={`flex flex-col border ${formatStyles(passOrFail, 'border')} items-stretch gap-2 overflow-hidden rounded-lg`}
    >
      {filePreviewOpen && (
        <ModalFilePreview
          open={filePreviewOpen}
          setOpen={setFilePreviewOpen}
          url={selectedFile?.url || selectedFile?.thumbnail_url}
          files={files}
          withNavigationButtons={files?.length > 1}
          withFilesPreview
        />
      )}
      <div className={`${formatStyles(passOrFail, 'bg')} p-2 flex justify-between`}>
        <div className="font-semibold truncate">
          {category}
        </div>
        <div className="text-sm text-gray-dark">
          Category
        </div>
      </div>
      <div className="mx-2 flex justify-between">
        <div className="flex flex-col gap-2">
          <div className="font-semibold">
            {name}
          </div>
          <div className={`text-xs py-1 px-6 ${formatStyles(passOrFail, 'buttonBg', 'textColor')} text-center rounded-3xl w-max font-semibold`}>
            {passOrFail ? "Pass" : "Fail"}
          </div>
        </div>
        <div className="flex flex-col gap-2 text-gray-dark text-right text-sm">
          {cardRowsName?.map((name) => (<div key={name}>{name}</div>))}
        </div>
      </div>
      <div className={`mx-2 py-1 border-t ${formatStyles(passOrFail, 'divider')} flex relative text-sm`}>
        {(notes || files?.length > 0) ? (
          <div className={`${isOpened ? '' : 'truncate'} pr-4`}>
            {notes}
          </div>)
        : (<div className="text-gray-dark ml-auto">
            No comments
          </div>)  
        }
        {(notes || files?.length > 0) && (
          <ExpandButton
            onClick={() => setIsOpened((prev) => !prev)}
            className="w-4 absolute right-0 top-2 cursor-pointer h-4 text-green-regular"
          />
        )}
      </div>
      {files?.length > 0 && (
        <div className="md:gap-4 gap-2 grid grid-cols-2 relative mt-4 px-2 pb-2">
          {files?.length > maxViewElements && (
            <>
              <button onClick={onClickBack} className="absolute left-0 top-1/2 transform -translate-y-1/2 hover:opacity-50">
                <ChevronLeftIcon className="w-10 h-10 text-green-regular" />
              </button>
              <button onClick={onClickNext} className="absolute right-0 top-1/2 transform -translate-y-1/2 hover:opacity-50">
                <ChevronRightIcon className="w-10 h-10 text-green-regular" />
              </button>
            </>
          )}
          {files?.slice(carouselSettings?.currentSlideStart, carouselSettings.endSlideStart)?.map((item) => (
            <button
              type="button"
              onClick={() => { setSelectedFile(item); setFilePreviewOpen(true)}}
              className="w-full"
              key={item?.url}
              style={{ height: "104px"}}
            >
              <img className="w-full h-full rounded-xl object-cover" src={item?.thumbnail_url || item?.url} alt="FAQ" />
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

export default DVIRCard